// Smooth scroll on click the link with hash
export default function smoothScroll() {
    const activeClass = 'is-active';
    const anchors = document.querySelectorAll('[href^="#"]');
    const headerNav = document.getElementById('js-header-nav');
    const header = document.getElementById('js-main-header');
    const rwdTriggers = document.querySelectorAll('.js-rwd-trigger');

    anchors.forEach(anchor => {
        anchor.addEventListener('click', event => {
            event.preventDefault();
            const blockPos = anchor.dataset.scrollBlock;
            const targetElId = anchor.hash.slice(1);
            const targetEl = document.getElementById(targetElId);

            if (headerNav.classList.contains('is-active')) {
                headerNav.classList.remove('is-active');
                header.classList.remove('is-active');
                rwdTriggers.forEach(rwdTrigger => {
                    if (rwdTrigger.classList.contains(activeClass)) {
                        rwdTrigger.classList.remove(activeClass);
                    }
                });
            }

            if (targetEl) {
                targetEl.scrollIntoView({
                    block: blockPos,
                    behavior: 'smooth',
                });
            } else {
                // Hotfix
                window.location.href =
                    document.location.origin + '#' + targetElId;
            }
        });
    });
}
