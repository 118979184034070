import 'jquery-mask-plugin';
const { default: ageGate } = require('./functions/age-gate');
const { default: headerMenu } = require('./functions/header-menu');
const { default: coctailsSlider } = require('./functions/coctails-slider');
const { default: selectInit } = require('./modules/select2');
const { default: smoothScroll } = require('./functions/smooth-scroll');
const { default: cotwGallery } = require('./functions/cotw-gallery');
const { default: bottomSheet } = require('./functions/bottom-sheet');

var documentReady = function () {
    ageGate();
    selectInit();
    headerMenu();
    coctailsSlider();
    smoothScroll();
    cotwGallery();
    bottomSheet();
    $('.js-input-postal-code').mask('00-000');
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
