import Swiper from 'swiper';
export default function coctailsSlider() {
    const slider = '#js-coctails-slider';
    const coctailsSlider = new Swiper(slider, {
        slideActiveClass: 'is-active',
        slidesPerView: 1.5,
        spaceBetween: 20,
        loop: false,
        breakpoints: {
            992: {
                slidesPerView: 2.5,
            },
            1300: {
                slidesPerView: 3.5,
                spaceBetween: 40,
            },
        },
    });
}
