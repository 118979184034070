import Swiper, { Navigation, Pagination } from 'swiper';
export default function cotwGallery() {
    const galleryTriggers = document.querySelectorAll('.js-trigger-gallery');
    const close = document.getElementById('js-cotw-swiper-popup-close');
    const target = document.getElementById('js-cotw-swiper-popup');

    if (galleryTriggers.length < 1) return;

    // Slider
    const slider = '#js-cotw-slider';
    const prev = '#js-cotw-slider-prev';
    const next = '#js-cotw-slider-next';
    Swiper.use([Navigation, Pagination]);
    const cotwSlider = new Swiper(slider, {
        slideActiveClass: 'is-active',
        slidesPerView: 1.25,
        spaceBetween: 20,
        loop: false,
        navigation: {
            nextEl: next,
            prevEl: prev,
            lockClass: 'is-hidden',
            disabledClass: 'is-disabled',
        },
        breakpoints: {
            992: {
                slidesPerView: 2,
            },
            1300: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
        },
        pagination: {
            el: '#js-cotw-slider-pagination',
            type: 'fraction',
            formatFractionCurrent: function (number) {
                return ('0' + number).slice(-2);
            },
            formatFractionTotal: function (number) {
                return ('0' + number).slice(-2);
            },
            renderFraction: function (currentClass, totalClass) {
                return (
                    '<span class="' +
                    currentClass +
                    '"></span>' +
                    ' / ' +
                    '<span class="' +
                    totalClass +
                    '"></span>'
                );
            },
        },
    });

    // Gallery
    const gallery = '#js-cotw-gallery';
    const galleryPrev = '#js-cotw-gallery-prev';
    const galleryNext = '#js-cotw-gallery-next';
    const cotwGallery = new Swiper(gallery, {
        slideActiveClass: 'is-active',
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        navigation: {
            nextEl: galleryNext,
            prevEl: galleryPrev,
            lockClass: 'is-hidden',
            disabledClass: 'is-disabled',
        },
        breakpoints: {
            992: {
                slidesPerView: 2,
            },
            1300: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
        },
        pagination: {
            el: '#js-cotw-gallery-pagination',
            type: 'fraction',
            formatFractionCurrent: function (number) {
                return ('0' + number).slice(-2);
            },
            formatFractionTotal: function (number) {
                return ('0' + number).slice(-2);
            },
            renderFraction: function (currentClass, totalClass) {
                return (
                    '<span class="' +
                    currentClass +
                    '"></span>' +
                    ' / ' +
                    '<span class="' +
                    totalClass +
                    '"></span>'
                );
            },
        },
    });

    // Popup
    // Open
    const activeClass = 'is-active';
    galleryTriggers.forEach(galleryTrigger => {
        galleryTrigger.addEventListener(
            'click',
            event => {
                event.preventDefault();
                let slideTo = galleryTrigger.dataset.slideto;
                if (galleryTrigger.classList.contains(activeClass)) {
                    galleryTrigger.classList.remove(activeClass);
                    target.classList.remove(activeClass);
                } else {
                    target.classList.add(activeClass);
                    galleryTrigger.classList.add(activeClass);
                    cotwGallery.slideTo(slideTo);
                }
            },
            false
        );
    });
    // Close
    close.addEventListener(
        'click',
        event => {
            event.preventDefault();
            target.classList.remove(activeClass);
            // TODO: zminieć pózniej :)
            galleryTriggers.forEach(galleryTrigger => {
                galleryTrigger.classList.remove(activeClass);
            });
        },
        false
    );
}
