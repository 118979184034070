export default function bottomSheet() {
    const sheetClass = 'js-bottom-sheet';
    const sheetOverlayClass = 'js-bottom-sheet-overlay';
    const sheetContentsClass = 'js-bottom-sheet-contents';
    const sheetDraggableClass = 'js-bottom-sheet-draggable';
    const sheetCloseClass = 'js-bottom-sheet-close';
    const sheetTriggerClass = 'js-bottom-sheet-trigger';
    let sheetHeight; // in vh
    let dragPosition;
    let sheet = false;

    function setSheetHeight(value) {
        if (sheet) {
            let contents = sheet.find('.' + sheetContentsClass);
            sheetHeight = Math.max(0, Math.min(100, value));
            contents.css('height', `${sheetHeight}vh`);
            if (sheetHeight === 100) {
                contents.addClass('is-fullscreen');
            } else {
                contents.removeClass('is-fullscreen');
            }
        }
    }

    function setIsSheetShown(value) {
        if (sheet) {
            sheet.attr('aria-hidden', !value);
            $('body').attr('aria-scrollable', !value);
            if (value) {
                $('html').css('overflow', 'hidden');
            } else {
                $('html').removeAttr('style');
            }
        }
    }
    function touchPosition(event) {
        return event.touches ? event.touches[0] : event;
    }

    function onDragStart(e) {
        dragPosition = touchPosition(event).pageY;
        sheet = $($(e.target).attr('data-target'));
        if (sheet) {
            sheet.find('.' + sheetContentsClass).addClass('not-selectable');
        }
    }

    function onDragMove(event) {
        if (dragPosition === undefined) return;
        const y = touchPosition(event).pageY;
        const deltaY = dragPosition - y;
        const deltaHeight = (deltaY / window.innerHeight) * 100;
        setSheetHeight(sheetHeight + deltaHeight);
        dragPosition = y;
    }

    function onDragEnd() {
        dragPosition = undefined;
        if (sheet) {
            sheet.find('.' + sheetContentsClass).removeClass('not-selectable');
            if (sheetHeight < 50) {
                setIsSheetShown(false);
                setSheetHeight(0);
            } else if (sheetHeight > 80) {
                setSheetHeight(100);
            } else {
                setSheetHeight(70);
            }
        }
    }

    $('body').on('click', '.' + sheetTriggerClass, e => {
        e.preventDefault();
        sheet = $($(e.target).attr('data-target'));
        setSheetHeight(Math.min(70, (720 / window.innerHeight) * 100));
        setIsSheetShown(true);
    });

    $('body').on('click', '.' + sheetCloseClass, e => {
        sheet = $($(e.target).attr('data-target'));
        setIsSheetShown(false);
        setSheetHeight(0);
    });

    $('body').on('click', '.' + sheetOverlayClass, e => {
        setIsSheetShown(false);
        setSheetHeight(0);
    });

    $('.' + sheetDraggableClass).on('mousedown', e => {
        onDragStart(e);
    });
    $('.' + sheetDraggableClass).on('touchstart', e => {
        onDragStart(e);
    });
    $(window).on('mousemove', e => {
        onDragMove(e);
    });
    $(window).on('touchmove', e => {
        onDragMove(e);
    });
    $(window).on('mouseup', e => {
        onDragEnd(e);
    });
    $(window).on('touchend', e => {
        onDragEnd(e);
    });
}
