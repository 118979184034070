export default function headerMenu() {
    const h = document.getElementById('js-main-header');
    const activeClass = 'is-active';
    const rwdTriggers = document.querySelectorAll('.js-rwd-trigger');

    /**
     * close rwd dropdown
     */
    rwdTriggers.forEach(rwdTrigger => {
        rwdTrigger.addEventListener(
            'mousedown',
            event => {
                event.preventDefault();
                const target = rwdTrigger.dataset.target;
                const target_elem = document.getElementById(target);
                if (rwdTrigger.classList.contains(activeClass)) {
                    rwdTrigger.classList.remove(activeClass);
                    if (target == 'js-header-nav')
                        h.classList.remove(activeClass);
                    target_elem.classList.remove(activeClass);
                } else {
                    target_elem.classList.add(activeClass);
                    rwdTrigger.classList.add(activeClass);
                    if (target == 'js-header-nav') h.classList.add(activeClass);
                }
            },
            false
        );
    });
}
