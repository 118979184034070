function ageGate() {
    const showClass = 'is-show';
    const $ageGate = $('#js-age-gate');
    let validation = false;

    if (!getCookie('ageGate') && getCookie('ageGate') != 'checked') {
        $ageGate.addClass(showClass);
    }

    $('#js-age-gate-form').on('submit', e => {
        e.preventDefault();
        const inputBirthY = $('#js-age-gate-y').val();
        const inputBirthM = $('#js-age-gate-m').val();
        const inputBirthD = $('#js-age-gate-d').val();
        const inputBirthYear =
            inputBirthY + '-' + inputBirthM + '-' + inputBirthD;
        const $errorMessage = $('#js-age-gate-form-error');

        if (!inputBirthY || !inputBirthM || !inputBirthD) {
            $errorMessage.addClass(showClass);
            return;
        }

        $errorMessage.removeClass(showClass);

        validation = underAgeValidate(inputBirthYear);

        if (validation) {
            $ageGate.removeClass(showClass);
            setAgeGateCookie();
        } else {
            $errorMessage.addClass(showClass);
        }
    });

    const setAgeGateCookie = () => {
        let date = new Date();
        date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
        const expires = 'expires=' + date.toUTCString();
        document.cookie =
            'ageGate' + '=' + 'checked' + '; ' + expires + ';path=/';
    };

    function getCookie(cname) {
        let name = cname + '=';
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    function underAgeValidate(birthday) {
        // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
        var optimizedBirthday = birthday.replace(/-/g, '/');

        //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
        var myBirthday = new Date(optimizedBirthday);

        // set current day on 01:00:00 hours GMT+0100 (CET)
        var currentDate = new Date().toJSON().slice(0, 10) + ' 01:00:00';

        // calculate age comparing current date and borthday
        var myAge = ~~((Date.now(currentDate) - myBirthday) / 31557600000);

        if (myAge < 18) {
            return false;
        } else {
            return true;
        }
    }

    const inputs = Array.prototype.slice.call(
        document.querySelectorAll('.js-age-gate-input')
    );

    inputs.forEach(input => {
        input.addEventListener('keyup', event => {
            const num = Number(event.key);
            if (num && num >= 0 && num <= 9) {
                if (input.value.length >= input.maxLength) {
                    event.preventDefault();
                    focusNext();
                }
            }
        });
    });

    function focusNext() {
        const currInput = document.activeElement;
        const currInputIndex = inputs.indexOf(currInput);
        const nextinputIndex = (currInputIndex + 1) % inputs.length;
        const input = inputs[nextinputIndex];
        input.focus();
    }
}

export default ageGate;
