import 'select2';
export default function select2init() {
    $('.js-select2').select2({
        // minimumResultsForSearch: -1,
        dropDownClass: 'dropDownClass',
        dropDownAutoWidth: true,
        language: {
            noResults: function (params) {
                return 'Brak';
            },
        },
    });
}
